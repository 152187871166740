import { DocumentNode, gql } from '@apollo/client';
import { GetSearchParams } from 'shared/providers/dataProvider/types';

const search =  (params?: GetSearchParams): DocumentNode => {
  const query = `
    query {
      biograhiesSearch(
        query: "${params?.q || ''}"
      ) {
        ... on BiographiesSearchOutput {
          items {
            slug
            title
          }
        }
        ... on NoResult {
          message
        }
        ... on ErrorPayload {
          traceId
          errorMessage
          validationErrors {
            code
            message
          }
        }
      }
    }`;
  
  return  gql`${query}`;
};

const biographiesGraphqlServices = {
  search,
};

export default biographiesGraphqlServices;
