import { useState } from 'react';
import useResetPassword from 'shared/providers/authProvider/useResetPassword';
import {
  RESET_ERROR_MESSAGE,
  RESET_SUCCESS_EMAIL_MESSAGE,
  RESET_SUCCESS_MESSAGE,
  apiErrorToUiNotifier,
  uiNotifier,
} from 'shared/services';
import { DataErrorResult } from 'shared/types';
import { getPlatformLogo } from 'shared/utils/utils';

const useInteractionResetPasswordView = () => {
  const [isNotify, setIsNotify] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const { loading, forgotPassword, confirmNewPassword } = useResetPassword();
  const logoUrl = getPlatformLogo();

  const handleForgotPassword = async (email: string) => {
    const response = await forgotPassword(email);

    if (response.status) {
      setCurrentEmail(email);
      setIsEmailValid(true);
      uiNotifier('info', RESET_SUCCESS_EMAIL_MESSAGE);
    } else {
      const errorMessage = (response.data as DataErrorResult).message || 'Unknown error';
      apiErrorToUiNotifier(errorMessage)();
    }
  };

  const handleConfirmNewPassword = async (code: string, newPassword: string) => {
    const { status } = await confirmNewPassword(currentEmail, code, newPassword);
    if (status && !isNotify) {
      setIsNotify(true);
      uiNotifier('success', RESET_SUCCESS_MESSAGE);
    } else {
      if (!status) {
        apiErrorToUiNotifier(RESET_ERROR_MESSAGE)();
      }
    }

    setTimeout(() => {
      window.location.href = './articles';
    }, 3000);
  };

  return {
    logoUrl,
    loading,
    isEmailValid,
    handleForgotPassword,
    handleConfirmNewPassword,
  };
};

export default useInteractionResetPasswordView;
