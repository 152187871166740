import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import PreviewContent from 'shared/components/preview/PreviewContent';
import { apiConfig } from 'shared/config/env';
import { apiErrorToUiNotifier, LOAD_ERROR_MESSAGE } from 'shared/services';
import { i18n } from 'shared/i18n';
import { PodcastShow } from 'views/shows/PodcastsShowsListView';
import { useGetOne } from 'shared/providers';
import { resources } from 'shared/constants';

const PodcastShowPreview = ({ history, match }: RouteComponentProps<{ slug: string }>) => {
  const [podcastShowData, setPodcastShowData] = useState<PodcastShow>();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const { data, error } = useGetOne<PodcastShow>(resources.PODCAST_SHOWS, {
    id: match.params.slug,
    additionalResource: resources.PODCAST_SHOW,
    allowedRequest: !isUpdated,
  });

  const edit = () => {
    history.push(`/podcast-show/${match.params.slug}`);
  };

  const goBack = () => {
    history.goBack();
  };

  const preViewUrl = podcastShowData ? `${apiConfig.endpointUrl}/preview/list/podcasts/${podcastShowData.slug}` : '';

  useEffect(() => {
    if (!!data && !!data.slug && !isUpdated) {
      setPodcastShowData(data);
      setIsUpdated(true);
    } else {
      if (!!error) {
        apiErrorToUiNotifier(LOAD_ERROR_MESSAGE);
      }
    }
  }, [data, error, isUpdated]);

  return podcastShowData ? (
    <PreviewContent
      titleLabel={i18n('podcastShows.preview.title')}
      titleValue={podcastShowData.title}
      publicationDate={podcastShowData.publication.date}
      previewUrl={preViewUrl}
      onEditClicked={edit}
      onGoBackClicked={goBack}
    />
  ) : null;
};

export default withRouter(PodcastShowPreview);
