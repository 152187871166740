import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { apiConfig } from 'shared/config/env';
import PreviewContent from 'shared/components/preview/PreviewContent';
import { i18n } from 'shared/i18n';
import { useGetOne } from 'shared/providers';
import { resources } from 'shared/constants';
import { Workshop } from 'views/workshops/types';

const WorkshopPreview = ({ history, match }: RouteComponentProps<{ slug: string }>) => {
  const { data } = useGetOne<Workshop>(resources.WORKSHOPS, {
    id: match.params.slug,
    additionalResource: resources.WORKSHOP,
  });

  const edit = () => {
    history.push(`/workshop/${match.params.slug}`);
  };

  const goBack = () => {
    history.goBack();
  };

  const preViewUrl = data ? `${apiConfig.endpointUrl}/club/preview/workshopsDay/${data.slug}` : '';
  return (
    <PreviewContent
      titleLabel={i18n('workshops.preview')}
      titleValue={data?.title}
      publicationDate={data?.publication.date}
      previewUrl={preViewUrl}
      onEditClicked={edit}
      onGoBackClicked={goBack}
    />
  );
};

export default withRouter(WorkshopPreview);
