import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { apiConfig } from 'shared/config/env';
import PreviewContent from 'shared/components/preview/PreviewContent';
import { apiErrorToUiNotifier, FETCH_ERROR_MESSAGE } from 'shared/services';
import { EventDetail } from 'views/event/types';
import { useGetOne } from 'shared/providers';
import { resources } from 'shared/constants';

const EventPreview = ({ match, history }: RouteComponentProps<{ slug: string }>) => {
  const [event, setEvent] = useState<EventDetail>();

  const edit = () => {
    history.push(`/event/${match.params.slug}`);
  };

  const goBack = () => {
    history.goBack();
  };

  const { data, error } = useGetOne<EventDetail>(resources.EVENTS, {
    id: match.params.slug,
    additionalResource: resources.EVENT,
  });

  const preViewUrl = event ? `${apiConfig.endpointUrl}/club/preview/event/${event.slug}` : '';

  useEffect(() => {
    if (!!data?.slug) {
      setEvent((prevState) => ({ ...prevState, ...data }));
    } else {
      if (error) {
        apiErrorToUiNotifier(FETCH_ERROR_MESSAGE);
      }
    }
  }, [data, error]);

  return event ? (
    <PreviewContent
      titleValue={event.metadata.title}
      publicationDate={moment(event.publication.date).toISOString()}
      previewUrl={preViewUrl}
      onEditClicked={edit}
      onGoBackClicked={goBack}
    />
  ) : null;
};

export default withRouter(EventPreview);
