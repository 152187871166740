import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { apiConfig, } from 'shared/config/env';
import PreviewContent from 'shared/components/preview/PreviewContent';
import { Dossier } from 'views/dossiers/types';
import { resources } from 'shared/constants';
import { useGetOne } from 'shared/providers';

const DossierPreview = ({ history, match }: RouteComponentProps<{ slug: string }>) => {
  const [dossier, setDossier] = useState<Dossier>();
  const { data } = useGetOne<Dossier>(resources.DOSSIERS, {
    id: match.params.slug,
    additionalResource: resources.DOSSIER,
  });

  const editDossier = useCallback(() => {
    history.push(`/dossier/${match.params.slug}`);
  }, [history, match.params.slug]);

  useEffect(() => {
    if (data){
      setDossier(data);
    }
  }, [data]);

  const preViewUrl = dossier ? `${apiConfig.endpointUrl}/preview/dossier/${dossier.slug}` : '';

  https: return dossier ? (
    <PreviewContent
      titleValue={dossier.title}
      publicationDate={dossier.publication.date}
      previewUrl={preViewUrl}
      onEditClicked={editDossier}
      onGoBackClicked={history.goBack}
    />
  ) : null;
};

export default withRouter(DossierPreview);
