import paperjamLogo from 'shared/assets/img/logo.svg';
import delanoLogo from 'shared/assets/img/logo-delano.svg';
import { appConfig } from 'shared/config/env';
import { DelanoPlatform } from 'shared/models';
import { DataError, SelectOption, SlugAndTitle } from 'shared/types';
import { commonConstants } from 'shared/constants';

const errorMessages = commonConstants.RESET_ERROR_MESSAGES;

export const isStringNotEmpty = (value?: string | null) => value !== undefined && value !== null && value.trim() !== '';

export const isStringListNonEmpty = (list: string[]) => Array.isArray(list) && list.filter(isStringNotEmpty).length > 0;

export const isSelectOptionNonEmpty = (option: SelectOption | null) =>
  option !== null && option.value !== null && option.value.trim() !== '';

const pattern = new RegExp(
  '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
); // fragment locator

export const isValidUrl = (value: string) => Boolean(pattern.test(value.trim()));

export const onlyNonEmptyItemsFrom = (list: SelectOption[]) =>
  list.filter((item) => item && isStringNotEmpty(item.value));

export const extractValuesFrom = (list: SelectOption[]) => list.map((item) => item && item.value);

export const fromSlugTitleToSelectOptions = (items?: SlugAndTitle[]) =>
  items?.map(
    (item) =>
      (item && { label: item.title, value: item.slug }) || {
        label: '',
        value: '',
      },
  ) || [];

export const copyToClipboard = (value: string) => {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const searchDuplicates = (data: SelectOption[]): boolean => {
  if (data) {
    const uniqueSet = new Set();
    for (const obj of data) {
      const key = `${obj.value}`;
      if (uniqueSet.has(key)) {
        return true;
      }
      uniqueSet.add(key);
    }
  }
  return false;
};

export const getPlatformLogo = () => {
  const platformName = appConfig.platformName;
  switch (platformName) {
    case DelanoPlatform:
      return delanoLogo;
    default:
      return paperjamLogo;
  }
};

export const checkPasswordStrength = (value: string) => {
  const minLength = 8;
  const maxLength = 50;
  const hasUppercase = /[A-Z]/.test(value);
  const hasLowercase = /[a-z]/.test(value);
  const hasNumber = /[0-9]/.test(value);
  const hasSpecialChar = /[^A-Za-z0-9]/.test(value);

  const isLengthValid = value.length >= minLength && value.length <= maxLength;

  if (isLengthValid && hasUppercase && hasLowercase && hasNumber && hasSpecialChar) {
    return 3;
  } else if (isLengthValid && (hasUppercase || hasLowercase) && (hasNumber || hasSpecialChar)) {
    return 2;
  }
  return 1;
};

export const validateEmail = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  if (!email) {
    return commonConstants.DEFAULT_EMAIL_RESPONSE;
  }

  const emailParts = email.split('@');
  if (emailParts.length !== 2) {
    return commonConstants.DEFAULT_EMAIL_RESPONSE;
  }

  const [, domain] = emailParts;
  const domainLowerCase = domain.toLowerCase();

  return {
    status: emailPattern.test(email),
    isDomain: domainLowerCase !== commonConstants.DEFAULT_DOMAIN,
  };
};

export const validateAuthentificationCode = (code: string) => {
  if (!code || code.trim() === '' || code.length > 50) {
    return {
      status: false,
      message: errorMessages.codeError,
    };
  }
  return commonConstants.DEFAULT_VALIDATION_SUCCESS;
};

export const verifyPassword = (newPassword: string, password: string) => {
  if (!newPassword || !password || newPassword !== password) {
    return {
      status: false,
      message: errorMessages.passwordMatchError,
    };
  }
  return commonConstants.DEFAULT_VALIDATION_SUCCESS;
};

export const isDataError = (error: DataError): error is DataError => {
  return error && typeof error.status === 'number' && typeof error.detail === 'string';
};
