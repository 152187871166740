import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { DataErrorResult } from 'shared/types';

interface UseResetPasswordReturn {
  loading: boolean;
  forgotPassword: <T>(username: string) => Promise<RequestSuccess<T | DataErrorResult | string>>;
  confirmNewPassword: <T>(
    username: string,
    code: string,
    newPassword: string,
  ) => Promise<RequestSuccess<T | DataErrorResult | string>>;
}

interface RequestSuccess<T> {
  status: boolean;
  data: T;
}

const useResetPassword = (): UseResetPasswordReturn => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleAsyncOperation = async <T>(asyncFunc: () => Promise<T>): Promise<RequestSuccess<T | DataErrorResult>> => {
    try {
      setLoading(true);
      const data = await asyncFunc();
      return { status: true, data };
    } catch (err) {
      const error = err as Error;
      return handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const forgotPassword = <T>(username: string): Promise<RequestSuccess<T | DataErrorResult | string>> =>
    handleAsyncOperation(() => Auth.forgotPassword(username));

  const confirmNewPassword = <T>(
    username: string,
    code: string,
    newPassword: string,
  ): Promise<RequestSuccess<T | DataErrorResult | string>> =>
    handleAsyncOperation(() => Auth.forgotPasswordSubmit(username, code, newPassword));

  const handleErrorNotification = (err: Error) => {
    return { status: false, data: { message: err.message, status: 500 } } as RequestSuccess<DataErrorResult>;
  };

  return { loading, forgotPassword, confirmNewPassword };
};

export default useResetPassword;
